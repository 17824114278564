import { Popover } from "@mui/material";
import { ReactNode, useState } from "react";

import ProfilePopoverContent from "components/shared/ProfilePopoverContent";
import ProfilePopoverContentPlatform from "components/shared/ProfilePopoverContentPlatform";
import ProfilePopoverContentStream from "components/shared/ProfilePopoverContentStream";
import useIdentity from "hooks/useIdentity";

type Props = {
  as?: "div" | "span";
  avatar?: string;
  buttonStyle: object;
  companyName?: string;
  dataSource?: string;
  displayPronouns?: boolean;
  jobTitle?: string;
  location?: string;
  name?: string;
  pronouns?: string;
  target: ReactNode;
  userId: string;
  userType?: "MEMBER" | "ADMIN";
};

export default function ProfilePopover({
  as = "div",
  avatar,
  buttonStyle = {},
  companyName,
  dataSource = "",
  displayPronouns,
  jobTitle,
  location,
  name,
  pronouns,
  target,
  userId,
  userType,
}: Props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userId: myUserId } = useIdentity();

  const canInteract = userId !== myUserId && userType !== "ADMIN";
  const canView = userType !== "ADMIN";

  const onClose = () => setOpen(false);

  if (!userId) {
    return <>target</>;
  }

  const Component = as;

  const fullButtonStyle = { ...buttonStyle, cursor: "pointer" };

  return (
    <>
      <Component
        role="button"
        style={fullButtonStyle}
        tabIndex={0}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.target);
        }}
        onKeyPress={(e) => {
          setOpen(true);
          setAnchorEl(e.target);
        }}
      >
        {target}
      </Component>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setOpen(false)}
      >
        {!dataSource && (
          <ProfilePopoverContent
            avatar={avatar}
            canInteract={canInteract}
            canView={canView}
            companyName={companyName}
            displayPronouns={displayPronouns}
            jobTitle={jobTitle}
            location={location}
            name={name}
            pronouns={pronouns}
            userId={userId}
            onClose={onClose}
          />
        )}

        {dataSource === "platform" && (
          <ProfilePopoverContentPlatform userId={userId} onClose={onClose} />
        )}

        {dataSource === "STREAM" && (
          <ProfilePopoverContentStream userId={userId} onClose={onClose} />
        )}
      </Popover>
    </>
  );
}
