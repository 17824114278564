import { Close } from "@mui/icons-material";
import { Box, IconButton, Paper, Skeleton, styled } from "@mui/material";

const Root = styled(Paper)({
  width: "330px",
  textAlign: "center",
});

const Header = styled(Box)({
  padding: "24px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#252422",
});

const StyledSkeleton = styled(Skeleton)({
  marginBottom: "13px",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "16px",
  right: "16px",
  zIndex: 10,
});

const LocationWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "8px",
});

const Name = styled(Box)({
  marginBottom: "8px",
});

const Title = styled(Box)({
  marginBottom: "8px",
});

const Company = styled(Box)({
  marginBottom: "8px",
});

export default function ProfilePopoverContentLoading({ onClose }) {
  return (
    <Root>
      <CloseButton size="small" onClick={onClose}>
        <Close />
      </CloseButton>
      <Header>
        <StyledSkeleton height={133} variant="circular" width={133} />
        <LocationWrapper>
          <Skeleton width={80} />
        </LocationWrapper>
        <Name>
          <Skeleton width={150} />
        </Name>
        <Title>
          <Skeleton width={100} />
        </Title>
        <Company>
          <Skeleton width={200} />
        </Company>
      </Header>
    </Root>
  );
}
