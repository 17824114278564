import { useAutoCompleteUsers } from "@communityco/chat-state";
import {
  Autocomplete,
  Chip,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import Avatar from "components/shared/Avatar";

const StyledAutocomplete = styled(Autocomplete)(() => ({
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    minHeight: "40px",
    padding: "4px 14px",
  },
}));

const StyledAvatar = styled(Avatar)({
  marginRight: "12px",
});

const StyledChip = styled(Chip)({
  marginRight: "9px",
  "& .MuiChip-deleteIcon": {
    color: "#c4c4c4",
  },
  "& span": {
    maxHeight: "100%",
    maxWidth: "100%",
  },
});

export default function MemberSearchField({
  multiple = true,
  placeholder = "Type a name...",
  queryUsersLoading,
  selectedUsers,
  setSelectedUsers,
  startAdornment = (
    <Typography color="textSecondary" variant="subtitle2">
      To:
    </Typography>
  ),
}) {
  const [searchValue, setSearchValue] = useState("");
  const { loading, users } = useAutoCompleteUsers({ input: searchValue });
  const filteredUserOptions = users.filter((user) =>
    multiple
      ? selectedUsers.findIndex(
          (selectedUser) => selectedUser.userId === user.userId
        ) === -1
      : selectedUsers?.userId !== user.userId
  );

  return (
    <StyledAutocomplete
      fullWidth
      defaultValue={selectedUsers}
      disabled={queryUsersLoading}
      freeSolo={searchValue === ""}
      getOptionLabel={(option) => option.name}
      multiple={multiple}
      noOptionsText={
        loading ? (
          <Skeleton width="20%">
            <Typography>.</Typography>
          </Skeleton>
        ) : (
          "No matches"
        )
      }
      options={filteredUserOptions}
      renderInput={(params) => (
        <TextField
          autoFocus
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment ? (
              <>
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ) : null,
            endAdornment: null,
          }}
          placeholder={placeholder}
          size="small"
        />
      )}
      renderOption={(props, option) => (
        <ListItemButton {...props} dense sx={{ height: "48px" }}>
          <ListItemAvatar>
            <StyledAvatar alt={option.name} src={option.avatar} />
          </ListItemAvatar>
          <ListItemText
            primary={option.name}
            primaryTypographyProps={{
              color: "textPrimary",
              variant: "subtitle2",
            }}
            secondary={option.company}
            secondaryTypographyProps={{
              color: "textSecondary",
              variant: "caption",
            }}
          />
        </ListItemButton>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledChip
            key={option.avatar}
            avatar={<Avatar alt={option.name} src={option.avatar} />}
            label={option.name}
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))
      }
      value={selectedUsers}
      onChange={(event, newValue) => {
        setSelectedUsers(newValue);
      }}
      onInputChange={(e, value) => {
        setSearchValue(value);
      }}
    />
  );
}
