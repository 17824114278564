import { Check, PersonAdd } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, ButtonProps, Snackbar } from "@mui/material";
import { MouseEvent, useState } from "react";

import useIdentity from "hooks/useIdentity";

type Props = {
  disableIcon: boolean;
  displayLabel: string;
  isFollowing: boolean;
  label?: string;
  loading: boolean;
  notFollowingIcon?: boolean;
  toggle: (event: MouseEvent<HTMLElement>) => Promise<void>;
  pendoClass?: string;
  buttonProps: ButtonProps;
};

export default function FollowButton({
  buttonProps = {},
  disableIcon,
  displayLabel,
  isFollowing,
  label,
  loading = false,
  notFollowingIcon,
  toggle,
  pendoClass = "",
}: Props) {
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const closeError = () => setErrorOpen(false);
  const closeSuccess = () => setSuccessOpen(false);
  const { role } = useIdentity();

  const verb = label || (isFollowing ? "Following" : "Follow");
  const onClick = isFollowing === undefined ? null : toggle;
  let startIcon = notFollowingIcon ? <PersonAdd /> : null;
  if (isFollowing === true) startIcon = <Check />;

  if (role === "ADMIN") {
    return null;
  }

  return (
    <>
      <Snackbar autoHideDuration={6000} open={errorOpen} onClose={closeError}>
        <Alert severity="error" onClose={closeError}>
          There was a error ${errorOpen} {displayLabel}.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={successOpen}
        onClose={closeSuccess}
      >
        <Alert severity="success" onClose={closeSuccess}>
          You are now following {displayLabel}!
        </Alert>
      </Snackbar>
      <LoadingButton
        className={pendoClass ?? ""}
        loading={loading}
        startIcon={!disableIcon && startIcon}
        variant={isFollowing ? "outlined" : "contained"}
        onClick={onClick}
        {...buttonProps}
      >
        {verb}
      </LoadingButton>
    </>
  );
}
