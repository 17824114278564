import baseUrl from "lib/site/baseUrl";
import bizjournalsQueryParamInjector from "lib/site/bizjournalsQueryParamInjector";

export default async function metadataFetcher(urlToFetch) {
  const { data, error } = await fetch(
    `${baseUrl("/api/url-metadata")}?url=${encodeURIComponent(urlToFetch)}`
  ).then((res) => res.json());

  if (!data || error) {
    const errorMessage = new Error(
      "An error occurred while fetching the data."
    );
    throw errorMessage;
  }

  if (data.url.match("bizjournals.com")) {
    data.url = bizjournalsQueryParamInjector(data.url);
  }
  if (data.title.match("&amp;038;")) {
    data.title = data.title.replaceAll("&amp;038;", "&");
  }
  return data;
}
