import { useInitializedState } from "@communityco/chat-state";
import { styled } from "@mui/system";
import { EditorState } from "prosemirror-state";
import React, { Dispatch, SetStateAction } from "react";

import Editor from "components/shared/inputs/Editor";
import MentionSuggestions from "components/shared/inputs/Editor/pickers/MentionSuggestions";
import cls from "lib/common/cls";

type Props = {
  state: EditorState;
  setState: Dispatch<SetStateAction<EditorState>>;
  editorProps?: any;
  className?: string;
};

const StyledEditor = styled(Editor)({
  "& .mention": {
    background: "#e7f2fe",
    color: "#0965c1",
    borderRadius: "3px",
    padding: "1px",
  },
});

const SocialEditor = React.forwardRef(
  ({ className, state, setState, editorProps }: Props, ref) => {
    const { isInitialized: isChatInitialized } = useInitializedState();

    return (
      <>
        <StyledEditor
          ref={ref}
          className={cls([className])}
          editorProps={editorProps}
          setState={setState}
          state={state}
        />
        {isChatInitialized && (
          <MentionSuggestions setState={setState} state={state} />
        )}
      </>
    );
  }
);

export default SocialEditor;
