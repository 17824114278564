import { useChannelByUsers } from "@communityco/chat-state";
import { LoadingButton } from "@mui/lab";
import Link from "next/link";
import { useEffect, useState } from "react";

export default function MessageMemberButton({
  children,
  fullWidth = false,
  userId,
  ...props
}) {
  const [url, setUrl] = useState("");
  const [userIds, setUserIds] = useState([userId]);
  const { channel, loading } = useChannelByUsers({
    userIds,
  });

  useEffect(() => {
    setUserIds([userId]);
  }, [userId]);

  useEffect(() => {
    if (!loading && channel) {
      setUrl(`/messages/${channel?.channelId}`);
    } else {
      setUrl(`/messages/new?userIds=${userId}`);
    }
  }, [userId, loading, channel]);

  return (
    <Link href={url}>
      <LoadingButton
        fullWidth={fullWidth}
        loading={loading}
        variant="contained"
        {...props}
      >
        {children}
      </LoadingButton>
    </Link>
  );
}
