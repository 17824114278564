import { useChannelUser } from "@communityco/chat-state";

import ProfilePopoverContent from "components/shared/ProfilePopoverContent";
import ProfilePopoverContentLoading from "components/shared/ProfilePopoverContent/loading";
import useIdentity from "hooks/useIdentity";

type Props = {
  userId: string;
  onClose: () => void;
};

export default function ProfilePopoverContentStream({
  userId,
  onClose,
}: Props) {
  const {
    avatar,
    company,
    displayPronouns,
    location,
    name,
    pronouns,
    role,
    title,
  } = useChannelUser({ userId }) || {};
  const { userId: myUserId } = useIdentity();
  const canInteract = userId !== myUserId && role !== "ADMIN";
  const canView = role !== "ADMIN";

  if (!name) {
    return <ProfilePopoverContentLoading onClose={onClose} />;
  }

  return (
    <ProfilePopoverContent
      avatar={avatar}
      canInteract={canInteract}
      canView={canView}
      companyName={company}
      displayPronouns={displayPronouns}
      jobTitle={title}
      location={location}
      name={name}
      pronouns={pronouns}
      userId={userId}
      onClose={onClose}
    />
  );
}
