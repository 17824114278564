import TagFaces from "@mui/icons-material/TagFaces";
import IconButton from "@mui/material/IconButton";
import { useRef, useState } from "react";

import EmojiPickerPopup from "components/shared/inputs/pickers/EmojiPicker";

import { MenuButtonComponentProps } from "../types";

export default function InsertEmojiButton({
  state,
  setState,
  view,
  ...props
}: MenuButtonComponentProps) {
  const emojiAnchorRef = useRef(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  return (
    <>
      <IconButton
        ref={emojiAnchorRef}
        size="large"
        title="Add Emoji"
        onClick={() => setEmojiPickerOpen(true)}
        {...props}
      >
        <TagFaces fontSize="small" />
      </IconButton>

      <EmojiPickerPopup
        anchorEl={emojiAnchorRef.current}
        open={emojiPickerOpen}
        placement="top"
        onClose={() => setEmojiPickerOpen(false)}
        onEmojiClick={(emojiObject) => {
          const { tr } = state;
          tr.insertText(emojiObject.emoji);
          setState(state.apply(tr));
          setEmojiPickerOpen(false);

          if (view) {
            view.focus();
          }
        }}
      />
    </>
  );
}
