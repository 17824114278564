import { TextSelection } from "prosemirror-state";

import { pluginKey as suggestionsPluginKey } from "components/shared/inputs/Editor/plugins/suggestions";
import schema from "../../schema";

export default function insertMention({ userId, name }) {
  return (state, setState) => {
    const { tr } = state;
    const { range, text } = suggestionsPluginKey.getState(state);

    // Set the selection to the trigger match
    tr.setSelection(TextSelection.create(state.doc, range.from, range.to));
    // Replace the selection with a text node containing the mention
    if (text?.[0] === " ") {
      tr.insertText(" ");
    }
    tr.replaceSelectionWith(
      schema.nodes.mention.create({
        href: `/profile/${userId}`,
        label: name,
      })
    );
    tr.insertText(" ");

    setState(state.apply(tr));

    return true;
  };
}
