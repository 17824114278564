import { Close, Room } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography, styled } from "@mui/material";
import Link from "next/link";

import Avatar from "components/shared/Avatar";
import FollowProfileButton from "components/shared/FollowProfileButton";
import MessageMemberButton from "components/shared/MessageMemberButton";

// Styled components
const Root = styled(Paper)({
  width: "330px",
  textAlign: "center",
});

const Header = styled(Box)({
  padding: "24px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#252422",
});

const StyledAvatar = styled(Avatar)({
  width: "130px",
  height: "130px",
  marginBottom: "8px",
  color: "#fff",
  fontWeight: 500,
  fontSize: "60px",
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "16px",
  right: "16px",
  zIndex: 10,
});

const LinkText = styled(Link)({
  marginTop: "8px",
  fontSize: "14px",
  letterSpacing: "0.44px",
  lineHeight: "24px",
});

const Footer = styled(Box)({
  backgroundColor: "#f4f4f4",
  display: "flex",
  justifyContent: "space-around",
  padding: "32px 0",
});

type Props = {
  avatar: string;
  canInteract: boolean;
  canView: boolean;
  companyName?: string;
  displayPronouns: boolean;
  jobTitle?: string;
  location?: string;
  name: string;
  onClose: () => void;
  pronouns: string;
  userId: string;
};

export default function ProfilePopoverContent({
  avatar,
  canInteract,
  canView,
  companyName,
  displayPronouns,
  jobTitle,
  location,
  name,
  onClose,
  pronouns,
  userId,
}: Props) {
  return (
    <Root>
      <CloseButton size="small" onClick={onClose}>
        <Close />
      </CloseButton>
      <Header>
        <StyledAvatar alt={name} height={133} src={avatar} width={133} />
        {location && (
          <Box alignItems="center" display="flex" gap="4px" marginBottom={1}>
            <Room sx={{ fontSize: "16px" }} />
            <Typography variant="subtitle2">{location}</Typography>
          </Box>
        )}
        <Typography variant="h2">{name}</Typography>
        {displayPronouns && pronouns && (
          <Typography
            color="textSecondary"
            sx={{ my: "4px" }}
            variant="caption"
          >
            ({pronouns})
          </Typography>
        )}
        {jobTitle && (
          <Typography variant="body2">
            {jobTitle} {companyName && "at"}
          </Typography>
        )}
        {companyName && <Typography variant="body2">{companyName}</Typography>}
        {canView && (
          <LinkText
            className="pendo_web-memberprofile-view"
            href={`/profile/${userId}`}
          >
            View full profile
          </LinkText>
        )}
      </Header>

      {canInteract && (
        <Footer>
          <FollowProfileButton userId={userId} userName={name} />
          <MessageMemberButton
            className="pendo_web-memberprofile-message"
            userId={userId}
          >
            Message
          </MessageMemberButton>
        </Footer>
      )}
    </Root>
  );
}
