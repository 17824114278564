import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

import findSuggestionMatch from "./findSuggestionMatch";

export const pluginKey = new PluginKey("suggestions");

const defaultState: {
  active: boolean;
  range: { from: number; to: number };
  query: string | null;
  text: string | null;
  composing: boolean;
  decorationId: string | null;
  decorationNode: HTMLElement | null;
  clientRect: DOMRect | null;
} = {
  active: false,
  range: { from: 0, to: 0 },
  query: null,
  text: null,
  composing: false,
  decorationId: null,
  decorationNode: null,
  clientRect: null,
};

/**
 * Controls the state for mention suggestions and inserts a decoration
 * into the editor when the trigger character is detected so that the
 * popup component can use it as an anchor. The UI for the suggestions
 * is handled separately in a component.
 */
export default function suggestions({
  triggerCharacter,
}: {
  triggerCharacter: string;
}) {
  return new Plugin({
    key: pluginKey,

    state: {
      // Initialize the plugin's internal state.
      init() {
        return defaultState;
      },

      // Apply changes to the plugin state from a view transaction.
      apply(transaction, prev) {
        const { selection } = transaction;
        const { empty, from } = selection;
        const next = { ...prev };

        // Might also need to check editor.view.composing here
        if (empty) {
          if (from < prev.range.from || from > prev.range.to) {
            next.active = false;
          }

          // Try to match against where our cursor currently is
          const match = findSuggestionMatch({
            triggerCharacter,
            $position: selection.$from,
          });

          const decorationId = prev.decorationId
            ? prev.decorationId
            : `id_${Math.floor(Math.random() * 0xffffffff)}`;
          const decorationNode = document.querySelector(
            `[data-decoration-id="${decorationId}"]`
          );

          // If we found a match, update the current state to show it
          if (match) {
            next.active = true;
            next.range = match.range;
            next.query = match.query;
            next.text = match.text;
            next.decorationId = decorationId;
            next.clientRect =
              decorationNode && decorationNode.getBoundingClientRect();
          } else {
            next.active = false;
          }
        }

        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.range = { from: 0, to: 0 };
          next.query = null;
          next.text = null;
          next.decorationId = null;
          next.decorationNode = null;
          next.clientRect = null;
        }

        return next;
      },
    },

    props: {
      decorations(state) {
        const { active, range, decorationId } = this.getState(state) || {};

        if (!active || !range || !decorationId) {
          return null;
        }

        return DecorationSet.create(state.doc, [
          Decoration.inline(range.from, range.to, {
            nodeName: "span",
            class: "mention",
            "data-decoration-id": decorationId,
          }),
        ]);
      },
    },
  });
}
