import React from "react";

import MarkdownElement from "components/markdown/MarkdownElement";
import parseMarkdown from "lib/markdown/parseMarkdown";

type Props = {
  markdown?: string;
};

const Markdown = React.memo(({ markdown }: Props) => {
  if (!markdown) {
    return null;
  }

  const mdast = parseMarkdown(markdown);
  return <MarkdownElement node={mdast} />;
});

export default Markdown;
