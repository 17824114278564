import { ResolvedPos } from "prosemirror-model";

import matchAll from "lib/common/matchAll";

/**
 * Finds the trigger character between the starting point of the current position
 * and the current position. When a match is found it returns its range, the match
 * text and the query which is the match text with the triggerCharacter removed
 */
export default function findSuggestionMatch({
  triggerCharacter,
  $position,
}: {
  triggerCharacter: string;
  $position: ResolvedPos;
}) {
  // Matching expressions used for later
  const escapedChar = triggerCharacter
    .split("")
    .map((c) => `\\${c}`)
    .join("");
  const regexp = new RegExp(`(?:^)?${escapedChar}[^\\s${escapedChar}]*`, "gm");

  const isTopLevelNode = $position.depth <= 0;
  const textFrom = isTopLevelNode ? 0 : $position.before();
  const textTo = $position.pos;
  const text = $position.doc.textBetween(textFrom, textTo, "\0", "\0");
  const match = Array.from(matchAll(regexp, text)).pop();

  if (!match || match.input === undefined || match.index === undefined) {
    return null;
  }

  const matchPrefix = match.input.slice(
    Math.max(0, match.index - 1),
    match.index
  );
  const matchPrefixIsSpace = /^[\s\0]?$/.test(matchPrefix);

  if (!matchPrefixIsSpace) {
    return null;
  }

  // The absolute position of the match in the document
  const from = match.index + $position.start();
  const to = from + match[0].length;

  // If the $position is located within the matched substring, return that range
  if (from < $position.pos && to >= $position.pos) {
    return {
      range: {
        from,
        to,
      },
      query: match[0].slice(triggerCharacter.length),
      text: match[0],
    };
  }

  return null;
}
