import { Box, ClickAwayListener, Popper, PopperProps } from "@mui/material";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";

type Props = {
  open: boolean;
  anchorEl: HTMLElement;
  onEmojiClick: (emoji: EmojiClickData) => void;
  onClose: () => void;
  placement: PopperProps["placement"];
};

export default function EmojiPickerPopup({
  open,
  anchorEl,
  onEmojiClick,
  onClose,
  placement,
}: Props) {
  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      sx={{ zIndex: 1300 }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Box>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </Box>
      </ClickAwayListener>
    </Popper>
  );
}
