import ProfilePopoverContent from "components/shared/ProfilePopoverContent";
import ProfilePopoverContentLoading from "components/shared/ProfilePopoverContent/loading";
import useIdentity from "hooks/useIdentity";
import useMember from "hooks/useMember";

type Props = {
  userId: string;
  onClose: () => void;
};

export default function ProfilePopoverContentPlatform({
  onClose,
  userId,
}: Props) {
  const { user, loading } = useMember(userId);
  const { userId: myUserId } = useIdentity();
  const canInteract = userId !== myUserId && user?.type !== "ADMIN";
  const canView = user?.type !== "ADMIN";

  if (loading) {
    return <ProfilePopoverContentLoading onClose={onClose} />;
  }

  return (
    <ProfilePopoverContent
      canInteract={canInteract}
      canView={canView}
      userId={userId}
      onClose={onClose}
      {...user.snapshot}
    />
  );
}
