import { useQuery } from "@apollo/client";

import { USER_BASIC_QUERY } from "graphql/user/queries";

export default function useMember(id) {
  const { data, loading, error } = useQuery(USER_BASIC_QUERY, {
    variables: { id },
  });

  return {
    user: data?.user ?? {},
    loading,
    error,
  };
}
