import { Box, Link } from "@mui/material";
import { useRouter } from "next/router";

import ProfilePopover from "components/shared/ProfilePopover";
import bizjournalsQueryParamInjector from "lib/site/bizjournalsQueryParamInjector";
import theme from "styles/theme";


function profileLinkUserId(uri) {
  const [, uuid] =
    /\/profile\/([\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12})(?:\?|#|\/|$)/i.exec(
      uri
    ) || [];
  return uuid;
}

function renderChildren(node, isParentBlock = false) {
  return node.children.map((subNode, i) => (
    // eslint-disable-next-line react/no-array-index-key, no-use-before-define
    <MarkdownElement key={i} node={subNode} parentBlock={isParentBlock} />
  ));
}

type MarkdownElementProps = {
  node: {
    type: string;
    value: string;
    url: string;
    title: string;
    ordered: boolean;
    children: boolean;
  };
  parentBlock?: boolean;
};

function MarkdownElement({ node, parentBlock }: MarkdownElementProps) {
  const { pathname } = useRouter();

  let userId;

  // Full MDAST Docs: https://github.com/syntax-tree/mdast#nodes
  switch (node.type) {
    // Literal types (value: "string")
    case "text":
    case "html": // don't allow raw HTML/YAML - treat as text
    case "yaml":
      return node.value;

    // Break types (no value or children)
    case "break":
      return <br />;

    case "thematicBreak":
      return <hr />;

    // Parent styled types (children: [])
    case "emphasis":
      return renderChildren(node);

    case "strong":
      return renderChildren(node);

    case "delete":
      return <s>{renderChildren(node)}</s>;

    case "link": {
      userId = profileLinkUserId(node.url);

      if (userId) {
        return (
          <ProfilePopover
            as="span"
            buttonStyle={{ display: "inline" }}
            dataSource="STREAM"
            target={
              <Box
                component="span"
                sx={
                  pathname === "/messages/[channelId]"
                    ? {
                      fontWeight: "600",
                    }
                    : {
                      color: "#0965c1",
                      padding: "1px",
                      background: "#e7f2fe",
                      borderRadius: "3px",
                      ...theme.typography.body1,
                      fontSize: "inherit",
                    }
                }
              >
                {renderChildren(node)}
              </Box>
            }
            userId={userId}
          />
        );
      }

      const href = node.url.match("bizjournals.com")
        ? bizjournalsQueryParamInjector(node.url)
        : node.url;

      return (
        <Link
          href={href}
          rel="noreferrer"
          target="_blank"
          title={node.title}
          underline="hover"
        >
          {renderChildren(node)}
        </Link>
      );
    }
    // Parent block types (children: [])
    case "list":
      if (node.ordered) {
        return <ol style={{ paddingLeft: "20px" }}>{renderChildren(node)}</ol>;
      }
      return renderChildren(node);

    case "listItem":
      return <li>{renderChildren(node, true)}</li>;

    // Treat tables and headers as <p> tags
    case "tableRow":
    case "heading":
    case "paragraph":
      if (parentBlock) {
        return <p>{renderChildren(node, true)}</p>;
      }
      return (
        <p style={{ margin: "0 0 16px", whiteSpace: "break-spaces" }}>
          {renderChildren(node, true)}
        </p>
      );

    // Root container
    case "root":
      return <span className="markdown-root">{renderChildren(node)}</span>;

    default:
      break;
  }

  if (node.children) {
    return renderChildren(node);
  }

  if (node.value) {
    return node.value;
  }

  return "";
}

export default MarkdownElement;
