import {
  useAutoCompleteUsers,
  useInitializedState,
} from "@communityco/chat-state";
import {
  Card,
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popper,
  Skeleton,
} from "@mui/material";

import Avatar from "components/shared/Avatar";

import { pluginKey as suggestionsPluginKey } from "../../plugins/suggestions";
import insertMention from "../../plugins/suggestions/insertMention";

function MentionSuggestionsPopper({ state, setState }) {
  const { active, query, decorationId } = suggestionsPluginKey.getState(state);

  // The node wrapping the query e.g. @john
  const decorationNode = document.querySelector(
    `[data-decoration-id="${decorationId}"]`
  );

  const { loading, users } = useAutoCompleteUsers({
    input: (active && query) || "a",
  });

  return (
    <ClickAwayListener onClickAway={() => null}>
      <Popper
        anchorEl={decorationNode}
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              boundariesElement: "window",
            },
          },
        ]}
        open={active}
        placement="top-start"
        sx={{
          zIndex: 1300,
        }}
      >
        <Card
          sx={{
            width: "500px",
            maxHeight: "300px",
            overflowY: "scroll",
          }}
        >
          {loading ? (
            <List disablePadding>
              {[...new Array(3)].map((val, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={i} dense divider>
                  <ListItemAvatar>
                    <Skeleton height={40} variant="circular" width={40} />
                  </ListItemAvatar>

                  <ListItemText
                    primary={<Skeleton width="25%" />}
                    secondary={<Skeleton width="35%" />}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <List disablePadding>
              {users.map((member) => (
                <ListItem
                  key={member.userId}
                  button
                  dense
                  divider
                  sx={{ minHeight: "61px" }}
                  onClick={() => {
                    insertMention(member)(state, setState);
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={member.name} src={member.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={member.name}
                    secondary={member.company}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Card>
      </Popper>
    </ClickAwayListener>
  );
}

export default function MentionSuggestions({ state, setState }) {
  const { isInitialized: isChatInitialized } = useInitializedState();

  if (!isChatInitialized) return null;

  return <MentionSuggestionsPopper setState={setState} state={state} />;
}
