import { ButtonProps } from "@mui/material";

import FollowButton from "components/shared/FollowButton";
import useFollow from "hooks/useFollow";
import useIdentity from "hooks/useIdentity";
import { useApp } from "lib/common/appProvider";

type Props = {
  userName: string;
  userId: string;
  buttonProps?: ButtonProps;
};

export default function FollowProfileButton({
  userName,
  userId,
  buttonProps,
}: Props) {
  const snackbarRef = useApp("snackbarRef");

  const { isUserFollowing, loading, toggleFollow } = useFollow({
    id: userId,
  });

  const { role, userId: meId } = useIdentity();

  if (userId === meId) return null;

  const handleToggle = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      await toggleFollow();
    } catch (e) {
      snackbarRef?.current?.callSnackbar(
        "Unable to complete action. Please try again.",
        "error"
      );
    }
  };

  const props = {
    buttonProps: {
      sx: { flexShrink: 0 },
      ...buttonProps,
    },
    disableIcon: true,
    displayLabel: userName,

    isFollowing: isUserFollowing,
    loading,
    pendoClass: isUserFollowing
      ? "pendo_web-memberprofile-unfollow"
      : "pendo_web-memberprofile-follow",
    toggle: handleToggle,
  };
  if (role === "ADMIN") {
    return null;
  }
  return <FollowButton {...props} />;
}
